import React from 'react';
import InnerLayout from "../../../hoc/InnerLayout/InnerLayout";
import Navigation from "../../../components/Navigation/Navigation";
import Layout from "../../../hoc/Layout/Layout";
import RentOwnComp from "../../../components/ContentRow/Step1/RentOwnComp/RentOwnComp";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const RentOwn = ( props ) => {
    const titles = {
        layoutTitle: 'TELL US ABOUT YOURSELF',
        sectionTitle: 'Do you rent or own your home?',
    };

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep1={(4/7)*100}
            active={'step1'}
        >
            <Helmet>
                <title>Saving for retirement - Do you rent or own your home?</title>
            </Helmet>
            <InnerLayout
                sectionTitle={titles.sectionTitle}
                modalContent='rentOwn'
            >
                <RentOwnComp/>
            </InnerLayout>

             <Navigation
                 isCompleted={ !!props.rentOrOwn}
                 next={retirementCalcLinks.anticipatedIncome}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        rentOrOwn: state.aboutYou.rentOrOwn,
    }
}


export default connect(mapStateToProps)(RentOwn);