import React, { useState } from 'react';
import classes from './RentOwnComp.module.scss'
import Radio from "../../../UI/Radio/Radio";
import Error from "../../../Error/Error";
import { connect } from "react-redux";
import { changeRentOrOwner, changePostalCode } from "../../../../store/actions/aboutYou";
import is from 'is_js'
import InputError from "../../../Error/InputError/InputError";

const RentOwnComp = ( props ) => {

    const [checked, setChecked] = useState(props.rentOrOwn ? props.rentOrOwn : '')
    const [postalCode, setPostalCode] = useState(props.postalCode ? props.postalCode : '')
    const[error, setError] = useState((!is.caPostalCode(postalCode) && !is.empty(postalCode)) ? true : false)
    const radios = ['Rent', 'Own']

    const changeValHandler = (e) => {
        const val = e.target.value

        setChecked(val)
        props.changeRentOrOwner(val)
    }

    const changePostalCode = (e) => {
        const val = e.target.value
        setPostalCode(val)
        setError(false)
        if (!is.caPostalCode(val) && !is.empty(val)){
            setError(true)
        }
        props.changePostalCode(val)
    }

    const errorStyle = {
        top: -15,
        left: '50%',
        marginLeft: 0,
        transform: 'translateX(-50%)'
    }

    return (
        <React.Fragment>
            {
                !props.rentOrOwn &&
                <Error message='Selection required' style={errorStyle}/>
            }
            <div className={classes.ChoosingType}>
                {
                    radios.map((value)=>{
                        return (
                            <Radio
                                key={value}
                                changed={changeValHandler}
                                name='rentOrOwn'
                                value={value}
                                isSelected={ checked === value }
                            />
                        )
                    })
                }
            </div>
            <div className={classes.PostalCode}>
                <div>
                    <input
                        type = "text"
                        name='postalCode'
                        value={postalCode}
                        placeholder="A1A1A1"
                        onChange={changePostalCode}
                    />
                </div>
                <div>Postal code</div>
                {
                    error &&
                    <InputError errorMessage="Invalid Postal code"/>
                }
            </div>
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        rentOrOwn: state.aboutYou.rentOrOwn,
        postalCode: state.aboutYou.postalCode,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeRentOrOwner: val => dispatch(changeRentOrOwner(val)),
        changePostalCode: val => dispatch(changePostalCode(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RentOwnComp);